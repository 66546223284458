import mercedes from "./images/brands/mercedes.svg";
import bayer from "./images/brands/bayer.svg";
import akapol from "./images/brands/akapol.svg";
import biogen from "./images/brands/biogen.svg";
import boehringer from "./images/brands/boehringer.svg";
import carat from "./images/brands/carat.svg";
import chevrolet from "./images/brands/chevrolet.svg";
import clorox from "./images/brands/clorox.png";
import craverolanis from "./images/brands/cravero-lanis.svg";
import framfiltros from "./images/brands/fram-filtros.svg";
import ipesasilo from "./images/brands/ipesasilo.svg";
import novartis from "./images/brands/novartis.svg";
import patagonia from "./images/brands/patagonia.svg";
import salvat from "./images/brands/salvat.svg";
import takeda from "./images/brands/takeda.svg";
import virulana from "./images/brands/virulana.svg";
import wd40 from "./images/brands/wd40.svg";
import ypf from "./images/brands/ypf.svg";

const brands = [
  {
    image: mercedes,
    name: "Mercedes Benz",
    style: { height: "100px" },
  },
  {
    image: bayer,
    name: "Bayer",
    style: { height: "120px" },
  },
  {
    image: akapol,
    name: "Akapol",
    style: { height: "100px" },
    // responsiveStyle: { height: "80px" },
  },
  {
    image: biogen,
    name: "Biogen",
    style: { height: "70px" },
    // responsiveStyle: { height: "60px" },
  },
  {
    image: boehringer,
    name: "Boehringer Ingelheim",
    style: { height: "100px" },
  },
  {
    image: carat,
    name: "Carat",
    // responsiveStyle: { height: "40px" },
  },
  {
    image: chevrolet,
    name: "Chevrolet",
    style: { height: "80px" },
    // responsiveStyle: { height: "70px" },
  },
  {
    image: clorox,
    name: "Clorox",
    style: { height: "100px" },
    // responsiveStyle: { height: "90px" },
  },
  {
    image: craverolanis,
    name: "Cravero Lanis",
    style: { height: "100px" },
  },
  {
    image: framfiltros,
    name: "FRAM Filtros",
    style: { height: "60px" },
    // responsiveStyle: { height: "50px" },
  },
  {
    image: ipesasilo,
    name: "IpesaSilo",
    style: { height: "40px" },
    // responsiveStyle: { height: "30px" },
  },
  {
    image: takeda,
    name: "Takeda",
    style: { height: "60px" },
    // // responsiveStyle: { height: "50px" },
  },
  {
    image: novartis,
    name: "Novartis",
    style: { height: "100px" },
    // responsiveStyle: { height: "50px" },
  },
  {
    image: patagonia,
    name: "PATAGONIA",
    style: { height: "100px" },
    // responsiveStyle: { height: "50px" },
  },
  {
    image: salvat,
    name: "SALVAT",
    style: { height: "112px" },
    // responsiveStyle: { height: "50px" },
  },
  {
    image: virulana,
    name: "Mapa Virulana",
    style: { height: "100px" },
    // responsiveStyle: { height: "50px" },
  },
  {
    image: wd40,
    name: "WD-40",
    style: { height: "100px" },
    // responsiveStyle: { height: "50px" },
  },
  {
    image: ypf,
    name: "YPF",
    style: { height: "80px" },
    // responsiveStyle: { height: "50px" },
  },
];
export default brands;
