import "./App.css";
import Logo from "./images/Logo.svg";
import AnimatedCardContainer from "./Components/DisplayCards";
import Team from "./Components/Team";
import Workers from "./Components/Workers";
import Brands from "./Components/Brands";
import Footer from "./Components/Footer";

function App() {
  return (
    <div className="App">
      <section className="w-full h-screen flex justify-center items-center bg-oficial-blue">
        <img
          src={Logo}
          alt="Logo INEMO"
          class="w-full max-w-md mx-auto p-10 md:p-0"
        />
      </section>

      <div>
        <AnimatedCardContainer />
      </div>
      <Team />
      <Workers />
      <Brands />
      <Footer />
    </div>
  );
}

export default App;
