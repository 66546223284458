import StrategicResearch from "./images/StratResearch.svg";
import BrandYCoaching from "./images/BrandYCoaching.svg";
import AgroIntelligence from "./images/AgroIntelligence.svg";
import Health from "./images/Health.svg";

const cardsData = [
  {
    id: 1,
    title: "STRATEGIC",
    go: ">",
    title2: "RESEARCH",
    image: StrategicResearch,
    className: "card-1",
    expandedText:
      "Utilizamos novedosas técnicas ad-hoc que se nutren de distintas ramas de la Psicología, la Antropología y la Etnografía para obtener insights exclusivos que combinan variables duras y blandas, de constatación porcentual a procesos cualitativos de análisis y social media research. ",
    titleStyle: { color: "#DA291C" },
  },

  {
    id: 3,
    title: "CONSULTORIA &",
    title2: "BRAND COACHING",
    go: ">",
    image: BrandYCoaching,
    className: "card-2",
    expandedText:
      "Nuestra área de brand coaching está destinada a fortalecer el principal activo que tiene una empresa: su marca. Desde una mirada holística, hacemos un análisis integral de cada área de la empresa para identificar las oportunidades de desarrollo.",
    titleStyle: { color: "#FB8A90" },
  },

  {
    id: 4,
    title: "INEMO AGRO",
    title2: "INTELLIGENCE",
    go: ">",
    image: AgroIntelligence,
    className: "card-3",
    expandedText:
      "Encuestadora oficial de Expoagro, la muestra agropecuaria más grande de la República Argentina, gracias a nuestra expertise interdisciplinaria, comprendemos las necesidades específicas del campo argentino y trabajamos codo a codo con productores agropecuarios, acompañándolos en la modernización de su área, para potenciar sus resultados.",
    titleStyle: { color: "#93D184" },
  },

  {
    id: 2,
    title: "INEMO",
    go: ">",
    title2: "HEALTH",
    image: Health,
    className: "card-4",
    expandedText:
      "Comprendemos las necesidades y desafíos del mundo de la salud y estamos entrenados en los aspectos más complejos de este tipo de research. Con nuestra expertise interdisciplinaria abarcamos las especificidades de la investigación médica con una mirada única e innovadora.",
    titleStyle: { color: "#3C5CD3" },
  },
];

export default cardsData;
