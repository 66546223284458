import React from "react";
import footer from "../images/footer.svg";

const Footer = () => {
  return (
    <div className="h-full md:m-auto md:w-[500px]  border-t-[0.5px] mx-5 mt-[100px] border-black flex align-middle items-center">
      <div className="flex px-5 mt-[30px]">
        <div className="text-right md:text-[15px] text-[10px] md:mt-[75px] mt-[60px] font-inter">
          <p>
            Scalabrini Ortiz 2471 - Piso 3<br />
            Buenos Aires - Argentina-(C1425DBF)
            <br />
            (+5411)4831-7595 / 4833-3977
            <br />
            info@inemo-research.com
          </p>
        </div>
        <div className="  p-5">
          <img
            src={footer}
            alt="Imagen de footer"
            className="max-w-full h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
