import React, { useState } from "react";
import "./Styles/Workers.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import gregorio from "../images/gregorio.png";
import mariana from "../images/mariana.png";
import soledad from "../images/soledad.png";
import andrea from "../images/andrea.png";
import pablo from "../images/pablo.png";

const people = [
  {
    image: gregorio,
    name: "GREGORIO ETCHEVERS",
    description:
      "Responsable del área cuantitativa, amante del marketing, la tecnología y el cine. Guitarrista frustrado pero cocinero consagrado. En constante aprendizaje y descubrimiento de nuevas metodologías de investigación.",
      style: { height: "120px", "margin-left":"10px" },
      responsiveStyle: {height: "100%", "margin-left":"-10px" },
      containerStyle: {  height: "100px", margin: "20px" },
    },
  {
    image: mariana,
    name: "MARIANA DAMA",
    description:
      "Psicologa junguiana existencialista. Observadora empedernida . Introvertida en la vida privada. Extrovertida en lo laboral. Madre trabajadora siendo lo que la vida vaya desafiando.",
    style: { height: "120px", "margin-left":"10px"},
    responsiveStyle: {height: "100%"},
    containerStyle: {  height: "100px", margin: "20px" },
  },
  {
    image: soledad,
    name: "SOLEDAD UCHIMA",
    description:
      "Responsable del área cualitativa y de Social Media Listening. Especializada y amante de la gastronomía y el turismo, formada en Estudios japoneses y promotora de la cultura okinawense en Argentina.",
    style: { height: "120px", width:"80%" },
    responsiveStyle: {height: "100%", "margin-left":"-10px"},
    containerStyle: {  height: "100px", margin:"20px" },
  },
  {
    image: andrea,
    name: "ANDREA GHILINO",
    description:
      "Hacedora, fundadora y estratega de INEMO. Contadora y artista plástica en búsqueda de verdadera innovación y de expandir nuestras mentes a través de sus obras. Amante de los viajes, el yoga y las comidas étnicas.",
    style: { height: "130px", "margin-left": "-10px"},
    responsiveStyle: {height: "120px", "margin-left": "-30px"},
    containerStyle: {  height: "100px", margin: "20px" },
  },
  {
    image: pablo,
    name: "PABLO DAMA",
    description:
      "Experto en técnicas cualitativas y cuantitativas no tradicionales, coach empresarial, coleccionista obsesivo de autos a escala, viajero empedernido y ferviente defensor la innovación como mecanismo central para la transformación y el crecimiento.",
      style: { height: "70px", width: "130px", "padding-top":"10px", "vertical-align": "middle", "margin-top": "24px" },
      responsiveStyle: {height: "45px", "margin-top":"40px", "margin-left":"-20px" , "vertical-align": "middle"},
      containerStyle: { height: "100px", margin:"20px", display: "grid" }
  },
];

const Workers = () => {
  const [currentPersonIndex, setCurrentPersonIndex] = useState(0);

  const goToNextPerson = () => {
    setCurrentPersonIndex((prevIndex) => (prevIndex + 1) % people.length);
  };

  const goToPreviousPerson = () => {
    setCurrentPersonIndex(
      (prevIndex) => (prevIndex - 1 + people.length) % people.length
    );
  };

  const currentPerson = people[currentPersonIndex];
  const anchoPantalla = window.innerWidth;


  if (anchoPantalla < 768) {
    currentPerson.style = currentPerson.responsiveStyle;
  } 


  return (
    <div className=" md:p-32 md:mb-0 h-full md:w-[1150px]  m-auto">
      <div className="md:flex md:flex-col h-[300px] md:h-[300px] m-20">
        <div
          className="md:w-[170px] h-auto"
          style={currentPerson.containerStyle}
        >
          <img
            src={currentPerson.image}
            alt={`${currentPerson.name}`}
            className=" h-auto  object-contain"
            style={currentPerson.style}
          />
        </div>
        {/* md:max-w-[120px]  max-w-[130px]*/}
        <div className="text-left align bottom mt-12" id="description">
          <h2
            id="name"
            className="text-[23px] md:text-[40px] md:text-xl font-semibold"
          >
            {currentPerson.name}
          </h2>
          <p className="text-[15px] md:text-[18px] mt-2">
            {currentPerson.description}
          </p>
        </div>
      </div>
      <div className="flex  justify-center mt-32">
        <button
          onClick={goToPreviousPerson}
          className="  focus:outline-none text-black py-1 md:font-bold text-[20px] px-2 rounded-md mr-2 select-none"
        >
          &lt;
        </button>
        <button
          onClick={goToNextPerson}
          className="  focus:outline-none text-black py-1  md:font-bold text-[20px] px-2 rounded-md mr-2 select-none"
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Workers;
