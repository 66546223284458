import "./Styles/Team.css";

export default function Team() {
  return (
    <div  className=" bg-black m-screen bg-hero-pattern bg-cover bg-center md:bg-contain p-0 md:pt-0 text-white text-center">
      <p id="team" className="tracking-widest md:text-[180px] text-[120px]">
        TEAM
      </p>
      <h2 id="bajada" className="text-[12px] md:text-[20px] pb-10 -mt-7">
        UN EQUIPO QUE TRABAJA HACE MAS DE 15 AÑOS JUNTOS
      </h2>
    </div>
  );
}
