import React, { useState } from "react";
import { motion } from "framer-motion";
import "./Styles/DisplayCards.css";

import cardsData from "../cardsData";

const AnimatedCardContainer = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (cardIndex) => {
    if (selectedCard === cardIndex) {
      setSelectedCard(null);
    } else {
      setSelectedCard(cardIndex);
    }
  };

  return (
    <div className="my-10 place-content-center">
      <section className="bg-white text-center  font-inter mt-0 md:h-[300px]">
        <p className=" md:text-[19px] text-[15px] md:pt-40 px-6 p-[40px] md:px-80">
          Somos una agencia con más de 20 años de experiencia en Argentina y
          Latinoamérica, integrada por investigadores con background académico y
          profesional en Psicología, Sociología, Marketing y Finanzas, lo que
          nos permite obtener una visión global e integradora de cada uno de los
          proyectos que realizamos.
        </p>
      </section>



      <div
        id="container"
        className="flex  justify-center md:mt-24 p-4 md:h-[300px] "
      >
        {cardsData.map((card) => (
          <motion.div
            key={card.id}
            className={`card ${
              selectedCard !== null && selectedCard !== card.id ? "hidden" : ""
            } ${selectedCard === card.id ? "expanded" : ""}`}
            onClick={() => handleCardClick(card.id)}
            initial={{ opacity: 1 }}
            animate={{
              opacity: 1,
              scale: selectedCard === card.id ? 1.1 : 1,
            }}
            exit={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className={card.className}>
              <img src={card.image} alt={card.title} />
              {selectedCard === card.id && (
                <p className="expanded-text">{card.expandedText}</p>
              )}
            </div>
            <div>
              <h1 id="card-title" style={card.titleStyle}>
                {card.title}
                <br /> {card.title2} <br />
                {card.go}
              </h1>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default AnimatedCardContainer;
