import React, { useState, useEffect } from "react";
import brands from "../brandsData";
import "./Styles/brands.css";
import "./Styles/Workers.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Brands = () => {
  const [currentBrandIndex, setCurrentBrandIndex] = useState(0);

  const changeBrand = () => {
    let increment = window.innerWidth < 768 ? 1 : 3; // Cambia 768 al ancho deseado
    const nextBrandIndex = (currentBrandIndex + increment) % brands.length;
    setCurrentBrandIndex(nextBrandIndex);
  };
  const increment = window.innerWidth < 768 ? 1 : 3;
  useEffect(() => {
    const brandInterval = setInterval(changeBrand, 2000); // Cambiar de marca cada 3 segundos

    return () => {
      clearInterval(brandInterval);
    };
  }, [currentBrandIndex]);

  // const currentBrand = brands[currentBrandIndex];
  // const anchoPantalla = window.innerWidth;


  // if (anchoPantalla < 768) {
  //   currentBrand.style = currentBrand.responsiveStyle;
  // } 

  return (
    <div>
      <div className="flex flex-col align-middle items-center align-center  justify-center p-20 md:h-[600px] h-[600px] md:mb-32 ">
        <h1 id="clientes">Clientes</h1>

        <div className="w-full md:h-[300px] md:w-[1000px]">
          <div
            id="container"
            className={`flex flex-row items-center space-y-4 md:justify-center md:space-x-4`}
          >
            {brands
              .slice(currentBrandIndex, currentBrandIndex + increment)
              .map((brand, index) => (
                <div
                  key={index}
                  className="w-full md:w-1/3 max-w-xs flex justify-center items-center relative"
                >
                  <div className="w-full flex flex-col items-center justify-center p-4">
                    <div className="h-[150px] mt-20 flex items-center justify-center ">
                      <img
                        src={brand.image}
                        alt={`${brand.name}`}
                        className="md:w-auto md:h-auto md:max-h-full "
                        style={brand.style}
                      />
                    </div>
                    <br />
                    <div className="h-[40px]"> 
                      <p className="text-center mt-2">{brand.name}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;
